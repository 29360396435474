import { Injectable } from '@angular/core';
import {
  BreadcrumbItem,
  DetailsTab,
} from '../../../../core/modals/details.modal';

import { SLAContractDetailsConfig } from '../config/sla-contract-details.config';
import { SLAContractService } from '../services/sla-contract.service';
import { DetailsBaseStore } from '../../../../core/store/details-base.store';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { SLAContractAssociatedItemSummaryDTO, SLAContractDetailsDTO } from '../dto/sla-contract.dto';
import { SLAContractConversion } from '../conversion/sla-contract.conversion';
import { FileDetailsDTO } from '../../../../shared/dto/file.dto';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class SLAContractDetailsStore extends DetailsBaseStore<SLAContractDetailsDTO> {
  tabs: DetailsTab[] = [];
  breadcrumbs: BreadcrumbItem[] = [];
  sanitizedPdfUrl: SafeResourceUrl;


  slaCount: SLAContractAssociatedItemSummaryDTO = {
    risks: 0,
    questions: 0,
    incidents: 0,
    action_plans: 0,
  };

  constructor(
    private slaContractDetailsConfig: SLAContractDetailsConfig,
    private slaContractService: SLAContractService,
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private slaContractConv: SLAContractConversion,
    private sanitizer: DomSanitizer
  ) {
    super();
    makeObservable(this, {
      tabs: observable,
      breadcrumbs: observable,
      convertResponseToDTO: action,
    });
    
  }

  override initialize(): void {
    this.tabs = this.slaContractDetailsConfig.slaTabs;
    this.breadcrumbs = this.slaContractDetailsConfig.breadcrumbs;
  }

  override get service(): any {
    return this.slaContractService;
  }

  loadFilePreview(): void {
    const id = this.details.id;
    const fileToken = this.details.document?.token;
    if (!fileToken) {
      return;
    }
    const url = this.service.previewDocument(id, fileToken);
    this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  fetchSlaContractAssociatedItemSummary(slaId: number): void {
    this.service.getSlaContractAssociatedItemSummary(slaId).subscribe({
      next: (result: SLAContractAssociatedItemSummaryDTO) => {
        runInAction(() => {
          this.slaCount = result;
        });
      },
      error: (error: any) => {
        console.error('Failed to fetch details:', error);
        runInAction(() => {});
      },
    });
  }

  convertResponseToDTO(response: any) {
    const details = this.slaContractConv.resToDetails(response);
    
    // Set document download URL if document and token exist
    let document: FileDetailsDTO | null = details.document;
    if (document) {
      document.downloadUrl = this.slaContractService.getDownloadURL(
        details.id,
        document.token
      );
      details.document = document;
    }

     // Update breadcrumbs label
    runInAction(() => {
      this.breadcrumbs[1].label = `${details.title}`;
    });
    return details;

  }
}
